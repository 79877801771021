.navbar-horizontal .navbar-nav .nav-link-icon {
  font-size: 1.3rem;
}
.navbar-horizontal.navbar-transparent .navbar-nav .nav-link {
  color: #8898aa;
}
.navbar-horizontal.navbar-transparent .navbar-nav .nav-link:focus,
.navbar-horizontal.navbar-transparent .navbar-nav .nav-link:hover {
  color: #1ba8e6;
}
.navbar-horizontal.navbar-transparent .navbar-nav .nav-link.disabled {
  color: #1ba8e6;
}
.navbar-horizontal.navbar-transparent .navbar-nav .active > .nav-link,
.navbar-horizontal.navbar-transparent .navbar-nav .nav-link.active,
.navbar-horizontal.navbar-transparent .navbar-nav .nav-link.show,
.navbar-horizontal.navbar-transparent .navbar-nav .show > .nav-link {
  color: #1ba8e6;
}
.navbar-horizontal.navbar-transparent .navbar-brand {
  color: #1ba8e6;
}
.navbar-horizontal.navbar-transparent .navbar-brand:focus,
.navbar-horizontal.navbar-transparent .navbar-brand:hover {
  color: #1ba8e6;
}

.navbar-horizontal .navbar-brand img {
  height: 55px;
}

.btn-primary {
  border-color: #99ca3a;
  background-color: #99ca3a;
  color: white !important;
}

.btn-primary:hover {
  border-color: #77ac0d;
  background-color: #77ac0d;
}

.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  border-color: #94c82a;
  background-color: #94c82a;
}

.btn-outline-primary {
  color: #77ac0d;
  border-color: #77ac0d;
}

.btn-outline-primary:hover {
  border-color: #77ac0d;
  background-color: #77ac0d;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  border-color: #94c82a;
}

.bg-secondary {
  background-color: #ffffff !important;
}

.form-control {
  color: black;
}

.form-control:focus {
  color: black;
}

.brand-blue {
  color: #1ba8e6;
}

.dashboard-icon {
  min-width: 2rem;
}

.info-icon {
  min-width: 2rem;
  height: 1.35rem;
}

.dashboard-card {
  box-shadow: 0 0 2rem 0 rgb(136 152 170 / 35%);
  margin: 10px;
  color: #525f7f;
}

.dashboard-card:hover {
  color: #1ba8e6;
}

.sidenav-header {
  height: 130px;
}

.navbar-vertical .navbar-brand-img,
.navbar-vertical .navbar-brand > img {
  max-height: 6rem;
}

.border-bottom {
  border-bottom: 6px solid !important;
  border-image: url('../img/brand/line.png') 0 0 1 0 stretch repeat;
}

.navbar-search-light .input-group {
  border-color: #1ba8e6;
  background-color: #e7f9ff;
}

.bg-primary {
  background-color: #1ba8e6 !important;
}

.btn-outline-success {
  color: #77ac0d;
  border-color: #77ac0d;
}

.btn-outline-success:hover {
  border-color: #77ac0d;
  background-color: #77ac0d;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  border-color: #94c82a;
  background-color: #94c82a;
}

.btn-outline-info {
  color: #1ba8e6;
  border-color: #1ba8e6;
}

.btn-outline-info:hover {
  border-color: #1ba8e6;
  background-color: #1ba8e6;
}

.btn-neutral {
  color: #77ac0d;
}

.btn-neutral:hover {
  color: #ffffff;
  border-color: #77ac0d;
  background-color: #77ac0d;
}

.btn-neutral:not(:disabled):not(.disabled):active,
.show > .btn-neutral.dropdown-toggle {
  color: #ffffff;
  border-color: #94c82a;
  background-color: #94c82a;
}

.bg-success {
  background-color: #77ac0d !important;
}

.btn-custom-size {
  padding: 0.5rem 2.5rem;
}

.custom-date {
  font-weight: 600;
  margin-left: 5px;
  padding: 0rem;
}

/* For calendar icon in date field */
::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".4" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 0.5rem;
}
::-webkit-calendar-picker-indicator:hover {
  -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04);
}

.bg-custom {
  background-color: #eef3f6;
}

.logo__container {
  left: 0;
  right: 0;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
}

.logo--sm {
  margin: auto;
  display: block;
}

.custom-login-div {
  padding: 2rem;
}

.custom-login {
  color: #1ba8e6;
}

thead {
  background-color: #f2f9fb;
}

.text-custom-orange {
  color: #ec804e;
}

.badge-dot i {
  width: 0.45rem;
  height: 0.45rem;
}

.sidenav {
  background-color: #f2f9fb;
}

.navbar-vertical.navbar-expand-xs.fixed-left {
  border-width: 0;
}

.text-blue-brand {
  color: #1ba8e6;
}

.footer-auto-bottom {
  position: relative;
}

.alert-danger {
  background-color: transparent;
  color: #f75676;
}

.full-loading {
  height: 16%;
  left: 0;
  right: 0;
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
}

.loading {
  min-height: 80px;
}

.loading-container {
  display: flex;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.custom-control-label::before {
  border: 1px solid #8898aa;
}

.custom-control-inline {
  margin-right: 3em;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #1ba8e6;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #1ba8e6;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1ba8e6;
  background-color: #1ba8e6;
}

.custom-checkbox .custom-control-input ~ .dhd-i-agree {
  font-size: 0.875rem;
  height: 100%;
  cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.12rem;
}

.nav-link:hover {
  color: #0f173f;
}

.description {
  white-space: pre-wrap;
}

.ck-editor {
  display: flex;
  flex-direction: column-reverse;
}

.ck-editor .ck-content {
  border: 1px solid #dee2e6;
  border-top: 0px;
  color: #000;
}

.floating-loader {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 99999;
}

.table-hover tbody tr:hover {
  cursor: pointer;
}
